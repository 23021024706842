/* eslint-disable import/no-extraneous-dependencies */
<template>
  <flat-pickr
    v-model="dateTime"
    placeholder="YYYY-mm-DD HH:mm"
    :class="formControl"
    :config="config"
    @input="inputChange"
    @on-change="onChange"
    @on-close="onClose"
  />
</template>

<script>
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'single',
    },
    enableTime: {
      type: Boolean,
      default: true,
    },
    formControl: {
      type: String,
      default: 'form-control',
    },
  },
  data() {
    return {
      dateTime: this.value,
      config: {
        enableTime: this.enableTime,
        time_24hr: true,
        allowInput: false,
        locale: this.$language(),
        mode: this.mode,
      },
    }
  },

  watch: {
    value(newValue, oldValue) {
      if (newValue !== this.dateTime) {
        this.dateTime = newValue
      }
    },
  },

  methods: {
    // onChange(event) {
    //   // eslint-disable-next-line camelcase
    //   const date_ob = event[0]

    //   // adjust 0 before single digit date
    //   const date = (`0${date_ob.getDate()}`).slice(-2)

    //   // current month
    //   const month = (`0${date_ob.getMonth() + 1}`).slice(-2)

    //   // current year
    //   const year = date_ob.getFullYear()

    //   // current hours
    //   const hours = date_ob.getHours()

    //   // current minutes
    //   const minutes = date_ob.getMinutes()

    //   // current seconds
    //   const seconds = date_ob.getSeconds()
    //   // prints date & time in YYYY-MM-DD HH:MM:SS format
    //   const a = `${year}-${month}-${date} ${hours}:${minutes}`
    //   this.$nextTick(() => {
    //     this.$emit('input', this.dateTime)
    //   })
    // },
    // doSomethingOnClose(event) {
    //
    //   // this.$emit('input', this.dateTime)
    // },
    inputChange() {
      this.$emit('input', this.dateTime)
    },
    onChange(selectedDay) {
      this.$emit('onChange', selectedDay)
    },
    onClose(selectedDay) {
      this.$emit('onClose', selectedDay)
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
