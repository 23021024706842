<template>
  <b-modal
    :id="id"
    size="xl"
    title="Thêm người lao động bị tai nạn"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateAccidentWorker"
    @hidden="resetState"
    @show="getDataWorkerNotRegister(paramUrl)"
  >
    <validation-observer ref="rule">
      <b-row v-if="$route.name !== 'accidentManagement'">
        <b-col
          md="12"
          xl="12"
          class="mt-1"
        >
          <b-form-group
            label-for="basicInput"
          >
            <b-form-checkbox
              id="checkbox-2"
              v-model="dataInput.isOwner"
              name="checkbox-2"
              @change="changeCheckbox"
            >Thuộc quyền quản lý</b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          v-if="!dataInput.isOwner"
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="basicInput"
          >
            <label for="label">Doanh nghiệp</label>
            <v-select
              v-model="paramUrl.bussinessId"
              :reduce="value => value.id"
              label="name"
              :disabled="$route.name === 'accidentManagement'"
              :options="dataAllBusiness || []"
              placeholder="Lựa chọn người lao động"
              @input="choiceWorker"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="basicInput"
          >
            <label for="label">Người lao động <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="{required: `Người lao động là bắt buộc`,}"
            >
              <div class="d-flex">
                <div
                  class="flex-grow-1"
                >
                  <v-select
                    v-model="dataInput.workerId"
                    :reduce="value => value.id"
                    label="name"
                    :options="dataAllWorker || []"
                    placeholder="Lựa chọn người lao động"
                    :class="errors.length > 0 ? 'is-invalid':null"
                    :disabled="(workerChoice && dataInput.isOwner) || changeDisable ? false : true"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
                <div
                  v-if="$route.name === 'accidentManagement'"
                  class="mb-auto ml-75 icon-add-topic rounded border border-primary"
                  variant="outline-primary"
                  @click="showModalAddWorker"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="20"
                    class="text-primary"
                  />
                </div>
              </div>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="basicInput"
          >
            <label for="label">Tình trạng thương tích <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="{required: `Tình trạng thương tích là bắt buộc`,}"
            >
              <v-select
                v-model="dataInput.statusWorker"
                :reduce="value => value.accidentEn"
                label="accidentVn"
                :options="dataAccidentReason || []"
                placeholder="Lựa chọn tình trạng thương tích"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="company"
          >
            <label label-for="basicInput">Số ngày nghỉ phép</label>
            <b-form-input
              v-model="dataInput.absent"
              type="number"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="company"
          >
            <label label-for="basicInput">Chi phí y tế</label>
            <b-form-input
              v-model="dataInput.healthCost"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="company"
          >
            <label label-for="basicInput">Chi phí trả lương trong thời gian điều trị</label>
            <b-form-input
              v-model="dataInput.salaryCost"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="company"
          >
            <label label-for="basicInput">Chi phí bồi thường</label>
            <b-form-input
              v-model="dataInput.indemnifyCost"
              type="number"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="company"
          >
            <label label-for="basicInput">Chi phí khác</label>
            <b-form-input
              v-model="dataInput.anotherCost"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="company"
          >
            <label label-for="basicInput">Thiệt hại tài sản</label>
            <b-form-input
              v-model="dataInput.assetCost"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="basicInput"
          >
            <label for="label">Yếu tố chấn thương <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="{required: `Yếu tố chấn thương là bắt buộc`,}"
            >
              <v-select
                v-model="dataInput.injuryElementId"
                :reduce="value => value.id"
                label="name"
                :options="dataAccidentType || []"
                placeholder="Lựa chọn yếu tố chấn thương"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <!-- @input="openInjuryType" -->
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="basicInput"
          >
            <label for="label">Loại chấn thương<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="{required: `Loại chấn thương là bắt buộc`,}"
            >
              <v-select
                v-model="dataInput.injuryReasonId"
                :reduce="value => value.id"
                label="name"
                :options="dataCombobox || []"
                placeholder="Lựa chọn loại chấn thương"
                :class="errors.length > 0 ? 'is-invalid':null"
                @input="openInjuryReason"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label>Số giờ làm việc trong ca</label>
            <b-form-input
              v-model="dataInput.hour"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          xl="4"
          class="mt-2"
        >
          <b-form-group>
            <b-form-checkbox
              v-model="dataInput.isManager"
            >Thuộc phạm vi quản lý của người sử dụng lao động</b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group>
        <label for="InputHelp">Mô tả tình trạng thương tích</label>
        <b-form-textarea
          id="InputHelp"
          v-model="dataInput.status"
          rows="3"
          no-resize
        />
      </b-form-group>
    </validation-observer>
    <modal-create-worker
      id="modal-create-worker"
      title="Thêm người lao động"
      @getListWorker="getListWorker"
    />
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BModal,
  BFormInput,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ModalCreateWorker from './ModalCreateWorker.vue'
import ConstantsApi from '../../constants/ConstantsApi'
import DataFilter from '../../constants/DataFilter'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    VSelect,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BButton,
    ModalCreateWorker,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    dataDetailAccidentWorker: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
    accidentId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      required,
      dataInput: {
        workerId: '',
        isOwner: true,
        statusWorker: '',
        absent: 0,
        healthCost: 0,
        salaryCost: 0,
        indemnifyCost: 0,
        anotherCost: 0,
        assetCost: 0,
        injuryType: '',
        injuryReasonId: '',
        status: '',
        hour: 0,
      },
      dataAccidentReason: DataFilter.dataAccidentReason,
      dataAccidentType: [],
      dataCombobox: [],
      choiceInjuryType: false,
      workerChoice: true,
      changeDisable: false,
      isSelfEmployment: true,
      dataAllWorker: [],
      paramUrl: {
        accidentId: this.$route.params.id || this.accidentId,
        bussinessId: null,
        workerId: '',
      },
    }
  },
  computed: {
    // ...mapGetters('workerPermit', ['dataAllWorker']),
    ...mapGetters('performTraining', ['dataAllBusiness']),
  },
  watch: {
    dataDetailAccidentWorker(val) {
      if (val && this.type === 'edit') {
        this.paramUrl.workerId = this.dataDetailAccidentWorker.workerId
        this.getDataWorkerNotRegister(this.paramUrl)
        this.dataInput = {
          workerId: this.dataDetailAccidentWorker.workerId,
          isOwner: this.dataDetailAccidentWorker.isOwner,
          statusWorker: this.dataDetailAccidentWorker.statusWorker,
          absent: this.dataDetailAccidentWorker.absent,
          healthCost: this.dataDetailAccidentWorker.healthCost,
          salaryCost: this.dataDetailAccidentWorker.salaryCost,
          indemnifyCost: this.dataDetailAccidentWorker.indemnifyCost,
          anotherCost: this.dataDetailAccidentWorker.anotherCost,
          assetCost: this.dataDetailAccidentWorker.assetCost,
          injuryType: this.dataDetailAccidentWorker.injuryType,
          injuryReasonId: this.dataDetailAccidentWorker.injuryReasonId,
          status: this.dataDetailAccidentWorker.status,
          hour: this.dataDetailAccidentWorker.hour,
          isManager: this.dataDetailAccidentWorker.isManager,
          injuryElementId: this.dataDetailAccidentWorker.injuryElementId,
        }
      }
    },
  },
  async created() {
    // if (this.dataInput.isOwner) {
    //   this.getDataWorkerNotRegister(this.paramUrl)
    // }
    if (this.$route.name === 'accidentManagement') {
      this.dataInput.isOwner = false
      await this.fetchDataComboboxAllBusiness(this.isSelfEmployment)
      await this.choiceWorker('td')
    }
    this.getDataInjuryElement()
    this.fetchDataCombobox()
  },
  methods: {
    // ...mapActions('workerPermit', ['fetchDataComboboxAllWorker']),
    ...mapActions('performTraining', ['fetchDataComboboxAllBusiness']),
    showModalAddWorker() {
      this.$bvModal.show('modal-create-worker')
    },
    changeCheckbox() {
      this.fetchDataComboboxAllBusiness(this.isSelfEmployment)
    },

    getListWorker(workerId) {
      this.dataInput.workerId = workerId
      this.getDataWorkerNotRegister(this.paramUrl)
      this.$bvModal.hide('modal-create-worker')
    },

    async getDataWorkerNotRegister(param) {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_WORKER_NOT_REGISTER, {
        params: param,
      })
      this.dataAllWorker = data?.data
    },
    async getDataInjuryElement() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_INJURY_ELEMENT)
      this.dataAccidentType = data?.data
    },

    choiceWorker(id) {
      this.paramUrl.bussinessId = id
      this.changeDisable = true
      if (!this.dataInput.isOwner) {
        this.dataInput.workerId = ''
        this.getDataWorkerNotRegister(this.paramUrl)
      }
      if (this.paramUrl.bussinessId === null) {
        this.changeDisable = false
      }
    },

    // Mở disable cho loại chấn thương
    // async openInjuryType() {
    //   if (this.dataInput.injuryType !== null) {
    //     await this.fetchDataCombobox(this.dataInput.injuryType)
    //     this.choiceInjuryType = true
    //     this.dataInput.injuryReasonId = ''
    //   }
    //   if (this.dataInput.injuryType === null) {
    //     this.dataInput.injuryReasonId = ''
    //     this.choiceInjuryType = false
    //   }
    // },

    async fetchDataCombobox(injuryType) {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_INJURY_REASON, { params: { injuryType } })
      this.dataCombobox = data?.data
    },

    openInjuryReason() {
      if (this.dataInput.injuryReasonId === null) {
        this.choiceInjuryType = false
      }
    },

    // Tạo quản lý bôi dưỡng
    handleCreateAccidentWorker(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateAccidentWorker', this.dataInput)
        }
      })
    },
    resetState() {
      this.paramUrl = {
        accidentId: this.$route.params.id || this.accidentId,
        bussinessId: this.$route.name === 'accidentManagement' ? 'td' : null,
        workerId: '',
      }
      this.dataInput = {
        workerId: '',
        isOwner: this.$route.name !== 'accidentManagement',
        statusWorker: '',
        absent: 0,
        healthCost: 0,
        salaryCost: 0,
        indemnifyCost: 0,
        anotherCost: 0,
        assetCost: 0,
        injuryType: '',
        injuryReasonId: '',
        status: '',
        hour: 0,
      }
    },
  },
}
</script>

<style lang="scss">
.button-plus {
  width: 38px;
  height: 38px;
}
</style>
