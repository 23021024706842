var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button-all-header',{staticClass:"button-table-worker",attrs:{"contentBtnAdd":'Thêm người lao động',"arrayExcel":[],"showBtnMultiDelete":_vm.showBtnMultiDelete,"hideImportFile":false,"hideExportFile":false,"hideDowload":false},on:{"clickDelete":_vm.deleteItems,"clickAdd":_vm.showModalCreate,"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataListWorkerGroup || [],"select-options":{
      enabled: true,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'code')?_c('span',{staticClass:"text-nowrap"},[_c('b-badge',{staticClass:"light-code",attrs:{"pill":""}},[_c('span',{staticClass:"text-name"},[_vm._v(_vm._s(_vm.$t(props.row.code)))])])],1):(props.column.field === 'actionFunction')?_c('span',[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"EditIcon","size":"18"},on:{"click":function($event){return _vm.showModalEdit(props.row.id)}}}),_c('feather-icon',{staticClass:"text-body ml-2",attrs:{"icon":"Trash2Icon","size":"18"},on:{"click":function($event){return _vm.deleteItem(props.row.id)}}})],1):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":_vm.handlePageClick}}),_c('modal-create-accident-worker',{attrs:{"id":_vm.modalAccidentWorker,"dataDetailAccidentWorker":_vm.dataDetailAccidentWorker,"accidentId":_vm.accidentId,"type":_vm.modalType},on:{"handleCreateAccidentWorker":function($event){return _vm.handleCreateAccidentWorker($event)}}}),_c('confirm-modal',{attrs:{"id":_vm.confirmModalId,"content":_vm.modalContent},on:{"accept":_vm.deleteAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }