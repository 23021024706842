export default {
  dataAccidentType: [
    {
      typeVn: 'Đầu, mặt, cổ',
      typeEn: 'Head',
    },
    {
      typeVn: 'Ngực, bụng',
      typeEn: 'Chest',
    },
    {
      typeVn: 'Phần chi trên',
      typeEn: 'Above',
    },
    {
      typeVn: 'Phần chi dưới',
      typeEn: 'Below',
    },
    {
      typeVn: 'Bỏng',
      typeEn: 'Burn',
    },
    {
      typeVn: 'Nhiễm độc các chất ở mức độ nặng',
      typeEn: 'Toxic',
    },
  ],
  dataAccidentReason: [
    {
      accidentVn: 'Chết',
      accidentEn: 'Death',
    },
    {
      accidentVn: 'Bị thương nặng',
      accidentEn: 'Serious',
    },
    {
      accidentVn: 'Bị thương nhẹ',
      accidentEn: 'Minor',
    },
  ],
}
