<template>
  <div>
    <!-- Phần ảnh và giới tính -->
    <b-media class="mb-3">
      <!-- gender sex. -->
      <label>Giới tính</label>
      <div
        class="demo-inline-spacing"
      >
        <b-form-radio
          v-model="dataGeneral.gender"
          :value="true"
          :disabled="disableProp"
        >
          Nam
        </b-form-radio>
        <b-form-radio
          v-model="dataGeneral.gender"
          :value="false"
          :disabled="disableProp"
        >
          Nữ
        </b-form-radio>
      </div>
      <template #aside>
        <div id="appImg">
          <div
            v-if="dataGeneral.avatar&&dataGeneral.avatar!==null&&dataGeneral.avatar!==''"
            class="image-input image-input-active d-flex"
          >
            <div
              class="image-preview"
            >
              <img
                class="img-responsive h-100"
                :src="($serverfile+dataGeneral.avatar)"
              >
              <b-dropdown
                class="remove-file"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                :disabled="disableProp"
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="CameraIcon"
                    size="16"
                    class="icon-camera-hover"
                  />
                </template>
                <b-dropdown-item
                  :disabled="disableProp"
                  @click="openinput"
                >
                  <feather-icon
                    icon="UploadIcon"
                    class="mr-50"
                  />
                  <span>Tải ảnh lên</span>
                </b-dropdown-item>
                <input
                  id="vue-file-upload-input"
                  type="file"
                  class="d-none"
                  :disabled="disableProp"
                  @change="addImage"
                >
                <b-dropdown-item
                  :disabled="disableProp"
                  @click="removeFile(index)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                  />
                  <span>Xóa ảnh</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div
            v-else
            class="image-input image-input-tbd d-flex"
          >
            <div
              class="image-preview dropzone d-flex justify-content-center align-items-center"
              :disabled="disableProp"
              @click="openinput"
            >
              <feather-icon
                icon="CameraIcon"
                class="icon-camera"
              />
            </div>
            <input
              id="vue-file-upload-input"
              type="file"
              class="d-none"
              :disabled="disableProp"
              @change="addImage"
            >
          </div>
        </div>
      </template>
    </b-media>

    <!-- User Info: Input Fields -->
    <validation-observer ref="rule">
      <b-form class="content-form">
        <h3 class="d-flex align-items-center mb-2">
          <feather-icon
            size="20"
            icon="InfoIcon"
            class="mr-75"
          />
          <span>
            Thông tin
          </span>
        </h3>
        <b-row>
          <!--  mã người lao động-->
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label-for="code"
            >
              <label for="code">Mã người lao động <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="code"
                rules="required"
                :custom-messages="{required: `Mã người lao động là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataGeneral.code"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- tên người lao đông -->
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label-for="jobCenter"
            >
              <label for="jobCenter">Tên người lao động <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="jobCenter"
                rules="required"
                :custom-messages="{required: `Tên người lao động là bắt buộc`}"
              >
                <b-form-input
                  v-model="dataGeneral.name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Quốc tịch -->
          <b-col
            v-if="isEdit"
            md="6"
            xl="4"
            class="mb-1"
          >
            <label for="jobCenter">Quốc tịch <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="countryId"
              rules="required"
              :custom-messages="{required: `Quốc tịch là bắt buộc`}"
            >
              <v-select
                v-model="dataGeneral.countryId"
                :reduce="title => title.id"
                label="name"
                :options="arrContry || []"
                :placeholder="'Quốc tịch'"
                :state="errors.length > 0 ? false:null"
                @input="$emit('checkId', dataGeneral.countryId)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!-- Ngày sinh-->
          <b-col
            v-if="isEdit"
            md="6"
            xl="4"
            class="mb-1"
          >
            <label for="jobCenter">Ngày sinh</label>
            <date-time-picker
              v-model="dataGeneral.birthDayString"
              :disabledInput="true"
            />
          </b-col>
          <!-- Mã BHXH-->
          <b-col
            v-if="isEdit"
            md="6"
            xl="4"
            class="mb-1"
          >
            <label for="jobCenter">Mã BHXH</label>
            <b-form-input
              v-model="dataGeneral.socialInsuranceCode"
            />
          </b-col>
          <!--Số CMND/CCCD/HC-->
          <b-col
            v-if="isEdit"
            md="6"
            xl="4"
            class="mb-1"
          >
            <label for="jobCenter">Số CMND/CCCD/HC</label>
            <b-form-input
              v-model="dataGeneral.identityCardNumber"
            />
          </b-col>
          <!--Nơi cấp-->
          <b-col
            v-if="isEdit"
            md="6"
            xl="4"
            class="mb-1"
          >
            <label for="jobCenter">Nơi cấp</label>
            <b-form-input
              v-model="dataGeneral.locationIssued"
            />
          </b-col>
          <!--Ngày hết hạn-->
          <b-col
            v-if="isEdit"
            md="6"
            xl="4"
            class="mb-1"
          >
            <label for="jobCenter">Ngày hết hạn</label>
            <date-time-picker
              v-model="dataGeneral.dateExpiredString"
              :disabledInput="true"
            />
          </b-col>
          <!--Số điện thoại-->
          <b-col
            v-if="isEdit"
            md="6"
            xl="4"
            class="mb-1"
          >
            <label for="jobCenter">Số điện thoại</label>
            <b-form-input
              v-model="dataGeneral.phoneNumber"
              type="number"
            />
          </b-col>
          <!--Địa chỉ-->
          <b-col
            v-if="isEdit"
            md="6"
            xl="4"
            class="mb-1"
          >
            <label for="jobCenter">Địa chỉ</label>
            <b-form-input
              v-model="dataGeneral.address"
            />
          </b-col>
          <!--Email-->
          <b-col
            v-if="isEdit"
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label-for="jobCenter"
            >
              <label for="jobCenter">Email </label>
              <b-form-input
                v-model="dataGeneral.email"
              />
            </b-form-group>
          </b-col>
          <!--Trình độ học vấn-->
          <b-col
            v-if="isEdit"
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group>
              <label for="basicInput">Trình độ học vấn <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="academicLevel"
                rules="required"
                :custom-messages="{required: `Trình độ học vấn là bắt buộc`}"
              >
                <v-select
                  v-model="dataGeneral.academicLevel"
                  :reduce="title => title.value"
                  label="label"
                  :options="academicLevel || []"
                  :placeholder="'Lựa chọn trình độ học vấn'"
                  :state="errors.length > 0 ? false:null"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--Ngành nghề-->
          <b-col
            v-if="isEdit"
            md="6"
            xl="4"
            class="mb-1"
          >
            <label>danh mục nghề nghiệp</label>
            <occupation-select
              v-model="dataGeneral.typeOfProfessionId"
            />
          </b-col>
          <!--Hình thức làm việc-->
          <b-col
            v-if="isEdit"
            md="6"
            xl="4"
            class="mb-1"
          >
            <label for="basicInput">Hình thức làm việc</label>
            <v-select
              v-model="dataGeneral.workingFormId"
              :reduce="title => title.id"
              label="name"
              :options="dataWorkingForm || []"
              :placeholder="'Lựa chọn hình thức làm việc'"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-col>
          <!--===============  Vị trí công việc  =================-->
          <b-col
            v-if="$route.name === 'accidentManagement'"
            md="4"
            xl="4"
          >
            <b-form-group>
              <label for="basicInput">Vị trí công việc <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="positionId"
                :rules="$route.name === 'accidentManagement'? 'required': ''"
                :custom-messages="{required: `Vị trí công việc là bắt buộc`}"
              >
                <v-select
                  v-model="dataGeneral.positionId"
                  :reduce="title => title.id"
                  label="name"
                  :options="arrPossition || []"
                  :placeholder="'Lựa chọn vị trí công việc'"
                  :state="errors.length > 0 ? false:null"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="$route.name === 'accidentManagement'"
            md="4"
            xl="4"
          >
            <b-form-group>
              <label for="basicInput">Loại hợp đồng lao động <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="contractType"
                :rules="$route.name === 'accidentManagement'? 'required': ''"
                :custom-messages="{required: `Loại hợp đồng lao động là bắt buộc`}"
              >
                <v-select
                  v-model="dataGeneral.contractType"
                  :reduce="title => title.contractType"
                  label="name"
                  :options="contractType || []"
                  :placeholder="'Lựa chọn loại hợp đồng lao động'"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <h3 class="d-flex align-items-center mb-2">
          <feather-icon
            size="20"
            icon="MapPinIcon"
            class="mr-75"
          />
          <span>
            Địa chỉ
          </span>
        </h3>
        <b-row>
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="company"
            >
              <label for="company">Tỉnh/Thành phố</label>
              <v-select
                id="company"
                v-model="dataGeneral.provinceId"
                :reduce="value => value.id"
                label="name"
                :options="provinces || []"
                placeholder="Lựa chọn tỉnh"
                @input="openSelectDistrict"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="company"
            >
              <label for="company">Quận/Huyện</label>
              <v-select
                id="company"
                v-model="dataGeneral.districtId"
                :reduce="value => value.id"
                label="name"
                :options="districts || []"
                placeholder="Lựa chọn huyện"
                :disabled="choiceDistrict ? false : true"
                @input="openSelectWard"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="basicInput"
            >
              <label for="company">Xã/Phường</label>
              <v-select
                id="company"
                v-model="dataGeneral.wardId"
                :reduce="value => value.id"
                label="name"
                :options="wards || []"
                :disabled="choiceWard ? false : true"
                placeholder="Lựa chọn xã"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormRadio,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import { required, email } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import OccupationSelect from '@/views/management-workers/occupation-list/pages/components/OccupationSelect.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    BDropdown,
    BDropdownItem,
    vSelect,
    OccupationSelect,
    DateTimePicker,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: true,
    },
    disableProp: {
      type: Boolean,
      default: false,
    },
    dataGeneral: {
      type: Object,
      default: _ => {},
    },
    contractType: {
      type: Array,
      default: _ => [],
    },
    arrPossition: {
      type: Array,
      default: _ => [],
    },
  },
  data() {
    return {
      email,
      required,
      academicLevel: [ // Lọc theo trình độ học vấn
        {
          label: 'Đại học',
          value: 'University',
        },
        {
          label: 'Cao đẳng',
          value: 'College',
        },
        {
          label: 'Trung cấp',
          value: 'Intermediate',
        },
        {
          label: 'Sơ cấp nghề',
          value: 'Primary',
        },
        {
          label: 'Chưa qua đào tạo',
          value: 'None',
        },
      ],
      arrContry: [],
      provinces: [],
      districts: [],
      wards: [],
      choiceDistrict: false,
      choiceWard: false,
      dataWorkingForm: [],
    }
  },
  computed: {
    ...mapState('worker', ['dataEdit']),
  },
  async created() {
    this.fetchComboboxWorking()
    const fetchContry = await this.fecthCountry()
    this.provinces = await this.fecthProvinces()
    if (fetchContry.oke) {
      this.arrContry = fetchContry.data
    }
    if (this.dataEdit && this.$route.params.id) {
      this.districts = await this.fecthDistricts(this.dataGeneral.provinceId)
      this.wards = await this.fecthWards(this.dataGeneral.districtId)
    }
  },
  methods: {
    ...mapActions('worker', ['fecthCountry']),
    ...mapActions('users', ['uploadFile']),
    ...mapActions('business', ['fecthProvinces', 'fecthDistricts', 'fecthWards']),

    // Combobox hình thức làm việc
    async fetchComboboxWorking() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_WORKING_FORM)
      this.dataWorkingForm = data?.data
    },

    // account
    openinput() {
      document.getElementById('vue-file-upload-input').click()
    },
    uploadPhoto() {
      this.openinput()
    },
    async addImage(e) {
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      const model = {
        files: file,
        isSecure: false,
      }
      const response = await this.uploadFile(model)
      this.dataGeneral.avatar = `${response}`
    },
    removeFile(index) {
      this.dataGeneral.avatar = null
    },

    async openSelectDistrict() {
      if (this.provinces.length > 0 && this.dataGeneral.provinceId !== null) {
        const data = await this.fecthDistricts(this.dataGeneral.provinceId)
        this.districts = data
        this.dataGeneral.districtId = null
        this.choiceDistrict = true
      }
      if (this.dataGeneral.provinceId === null) {
        this.dataGeneral.districtId = null
        this.dataGeneral.wardId = null
        this.choiceDistrict = false
        this.choiceWard = false
      }
    },

    async openSelectWard() {
      if (this.provinces.length > 0 && this.dataGeneral.districtId !== null) {
        const data = await this.fecthWards(this.dataGeneral.districtId)
        this.wards = data
        this.dataGeneral.wardId = null
        this.choiceWard = true
      }
      if (this.dataGeneral.districtId === null) {
        this.dataGeneral.wardId = null
        this.choiceWard = false
      }
    },

    // Danh sách Tỉnh/ Thành phố
    async fetchProvinces() {
      const data = await this.fecthProvinces()
      this.provinces = data
    },
  },
}
</script>

<style lang="scss">
#appImg {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  margin-right: 20px;
  .btn {
    padding: 4px !important;
  }
  .image-input {
    padding: 3px;
  }

  .image-preview {
    width: 96px;
    height: 96px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 6px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .icon-camera {
    color: white;
    height: 50px;
    width: 50px;
  }
  .dropzone {
    width: 96px;
    height: 96px;
  }
  .dropzone {
    background: #efefef;
    border-radius: 7%;
  }
  .remove-file {
    position: absolute;
    border-radius: 20px;
    margin-top: 28px;
    margin-left: -66px;
    width: 40px;
    height: 40px;
  }
  .remove-file:hover {
    color: white;
    background-color: rgba(130, 134, 139, 0.65);
  }
  .icon-camera-hover {
    width: 28px;
    height: 28px;
    color: rgba(104, 104, 104, 0);
  }
  .icon-camera-hover:hover {
    color: white;
  }
}
</style>
