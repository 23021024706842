export default {
  LIST_ACCIDENT_BUSINESS: 'Accident/accidents',
  DELETE_ACCIDENT_BUSINESS: 'Accident/delete-accident',
  CREATE_ACCIDENT_BUSINESS: 'Accident/add-accident',
  EDIT_ACCIDENT_BUSINESS: 'Accident/update-accident',
  DETAIL_ACCIDENT_BUSINESS: 'Accident/accident/',
  LIST_ACCIDENT_WORKERS: 'Accident/list-accident-worker',
  COMBOBOX_ACCIDENT_REASON: '/Accident/get-combobox-accident-reason',
  DELETE_ACCIDENT_WORKERS: 'Accident/delete-accident-worker',
  EDIT_ACCIDENT_WORKERS: 'Accident/update-accident-worker',
  CREATE_ACCIDENT_WORKERS: 'Accident/add-accident-worker',
  DETAIL_ACCIDENT_WORKERS: 'Accident/accidentWorker/',
  COMBOBOX_INJURY_REASON: 'InjuryReason/get-combobox-injury-reason',
  LIST_ACCIDENT_ADD_FILE: 'Accident/list-accident-file',
  ADD_FILE_TO_ACCIDENT: 'Accident/add-accident-file',
  DELETE_FILE_TO_ACCIDENT: 'Accident/delete-accident-file',
  SEND_ACCIDENT: 'Accident/send-accident',
  DOWNLOAD_ACCIDENT: 'Accident/download-file',
  LIST_WORKER_NOT_REGISTER: 'Accident/get-combobox-worker-not-register',
  COMBOBOX_CODE_ACCIDENT: 'Accident/get-accident-code',
  COMBOBOX_INJURY_ELEMENT: 'InjuryElement/get-combobox-injury-element',
}
