<template>
  <div id="infoAccidentId">
    <b-form class="content-form">
      <h3 class="d-flex align-items-center mb-2">
        <feather-icon
          size="20"
          icon="InfoIcon"
          class="mr-75"
        />
        <span>
          Thông tin
        </span>
      </h3>
      <b-row>
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="company"
          >
            <label label-for="basicInput">Mã tai nạn<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="customMessages"
            >
              <b-form-input
                id="basicInput"
                v-model="dataInfo.code"
                :disabled="true"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="company"
          >
            <label label-for="basicInput">Thời gian xảy ra tai nạn <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="date"
              rules="required"
              :custom-messages="customdateAccident"
            >
              <date-time-picker
                v-model="dataInfo.accidentTime"
                :enableTime="true"
                :class="{'is-invalid':errors.length > 0 }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="company"
          >
            <label label-for="basicInput">Nơi xảy ra tai nạn <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="customAddress"
            >
              <b-form-input
                id="basicInput"
                v-model="dataInfo.location"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="company"
            class="select-accident"
          >
            <label for="company">Nguyên nhân gây tai nạn <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="accidentReasonId"
              rules="required"
              :custom-messages="{required: `Nguyên nhân gây tai nạn là bắt buộc`}"
            >
              <v-select
                id="company"
                v-model="dataInfo.accidentReasonId"
                :reduce="value => value.id"
                label="name"
                :options="dataAccidentReason || []"
                :state="errors.length > 0 ? false:null"
                placeholder="Lựa chọn nguyên nhân gây tai nạn"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="company"
          >
            <label for="company">Loại tai nạn <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="accidentType"
              rules="required"
              :custom-messages="{required: `Loại tai nạn là bắt buộc`}"
            >
              <v-select
                id="company"
                v-model="dataInfo.accidentType"
                :reduce="value => value.accidentEn"
                label="accidentVn"
                :state="errors.length > 0 ? false:null"
                :options="accidentType || []"
                placeholder="Lựa chọn loại tai nạn"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          xl="4"
          class="checkbox-accident mt-2"
        >
          <b-form-group
            label-for="basicInput"
          >
            <b-form-checkbox
              id="checkbox-1"
              v-model="dataInfo.isSubsidize"
              name="checkbox-1"
            >Tai nạn được hưởng trợ cấp</b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="company"
          >
            <label label-for="basicInput">Số lao động đã được nhận trở lại làm việc</label>
            <b-form-input
              v-model="dataInfo.workerReturn"
              type="number"
            />
          </b-form-group>
        </b-col>
        <!-- //đã được khai báo, điều tra -->
        <b-col
          md="8"
          xl="8"
          class="d-flex flex-wrap justify-content-between"
        >
          <b-form-group
            label-for="basicInput"
            class="checkbox-accident mt-2"
          >
            <b-form-checkbox
              v-model="dataInfo.isInvestigate "
            >Đã được khai báo, điều tra</b-form-checkbox>
          </b-form-group>
          <b-form-group
            label-for="basicInput"
            class="checkbox-accident mt-2"
          >
            <b-form-checkbox
              v-model="dataInfo.isCriminalProsecution"
            >Đã truy cứu trách nhiệm hình sự</b-form-checkbox>
          </b-form-group>
          <b-form-group
            label-for="basicInput"
            class="checkbox-accident mt-2"
          >
            <b-form-checkbox
              v-model="dataInfo.isEvaluation"
            >Đã giám định tỉ lệ suy giảm khả năng lao động</b-form-checkbox>
          </b-form-group>
        </b-col>

      </b-row>

      <h3 class="d-flex align-items-center mt-1 mb-2">
        <feather-icon
          size="20"
          icon="MapPinIcon"
          class="mr-75"
        />
        <span>
          Địa chỉ
        </span>
      </h3>
      <b-row>
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="company"
          >
            <label for="company">Tỉnh/Thành phố</label>
            <v-select
              id="company"
              v-model="dataInfo.provinceId"
              :reduce="value => value.id"
              label="name"
              :options="provinces || []"
              placeholder="Lựa chọn tỉnh"
              @input="openSelectDistrict"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="company"
          >
            <label for="company">Quận/Huyện</label>
            <v-select
              id="company"
              v-model="dataInfo.districtId"
              :reduce="value => value.id"
              label="name"
              :options="districts || []"
              placeholder="Lựa chọn huyện"
              :disabled="choiceDistrict ? false : true"
              @input="openSelectWard"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="basicInput"
          >
            <label for="company">Xã/Phường</label>
            <v-select
              id="company"
              v-model="dataInfo.wardId"
              :reduce="value => value.id"
              label="name"
              :options="wards || []"
              :disabled="choiceWard ? false : true"
              placeholder="Lựa chọn xã"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>

      <h3 class="d-flex align-items-center mt-1 mb-2">
        <feather-icon
          size="20"
          icon="FileTextIcon"
          class="mr-75"
        />
        <span>
          Sơ bộ diễn biến vụ tai nạn
        </span>
      </h3>
      <b-row>
        <b-col>
          <b-form-group
            label-for="basicInput"
          >
            <label for="basicInput">Chi tiết</label>
            <b-form-textarea
              id="basicInput"
              v-model="dataInfo.description"
              rows="3"
              no-resize
            />
          </b-form-group>
        </b-col>
      </b-row>

      <h3 class="d-flex align-items-center mt-1 mb-2">
        <feather-icon
          size="20"
          icon="PaperClipIcon"
          class="mr-75"
        />
        <span>
          Tải file đính kèm
        </span>
      </h3>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-for="date"
          >
            <label for="basicInput">File đính kèm</label>
            <div class="d-flex">
              <b-form-input
                v-model="dataInfo.fileName"
                :disabled="true"
              />

              <input
                ref="upload-file"
                type="file"
                class="d-none"
                @change="importFileExcel"
              />
              <b-button
                variant="outline-primary"
                class="btn-button-filed ml-1"
                @click="uploadFile"
              >
                <feather-icon
                  icon="UploadIcon"
                  for="file"
                  class="input_label text-primary"
                />
                <label
                  class="text-primary ml-50"
                >Tải lên</label>
              </b-button>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import { required, email } from '@validations'
import DateTimePicker from '@/components/dateTimePicker/DateTimePicker.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormInput,
    DateTimePicker,
    BFormCheckbox,
    BForm,
    ValidationProvider,
    BFormTextarea,
  },
  props: {
    dataInfo: {
      type: Object,
      default: _ => {},
    },
    accidentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      customMessages: {
        required: 'Mã tai nạn là bắt buộc',
      },
      customdateAccident: {
        required: 'Thời gian xảy ra tai nạn là bắt buộc',
      },
      customAddress: {
        required: 'Nơi xảy ra tai nạn là bắt buộc',
      },
      dataAccidentReason: [],
      accidentType: [
        {
          accidentVn: 'Tai nạn lao động làm chết người lao động',
          accidentEn: 'Death',
        },
        {
          accidentVn: 'Tai nạn lao động làm người lao động bị thương nặng',
          accidentEn: 'SeriouslyInjured',
        },
        {
          accidentVn: 'Tai nạn lao động làm người lao động bị thương nhẹ',
          accidentEn: 'MinorInjured',
        },
      ],
      provinces: [],
      districts: [],
      wards: [],
      choiceDistrict: false,
      choiceWard: false,
      isEdit: false,
    }
  },
  async created() {
    this.fetchComboboxAccident()
    this.provinces = await this.fecthProvinces()
    if (this.dataInfo && (this.$route.params.id || this.accidentId)) {
      this.isEdit = true
      this.districts = await this.fecthDistricts(this.dataInfo.provinceId)
      this.wards = await this.fecthWards(this.dataInfo.districtId)
    }
    if (!this.$route.params.id) {
      this.getComboboxCodeAccident()
    }
  },
  methods: {
    ...mapActions('business', ['fecthProvinces', 'fecthDistricts', 'fecthWards']),

    // Danh sách combobox nguyên nhân gây tai nạn
    async fetchComboboxAccident() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_ACCIDENT_REASON)
      this.dataAccidentReason = data.data
    },

    async getComboboxCodeAccident() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_CODE_ACCIDENT)
      this.dataInfo.code = data?.data
    },

    async openSelectDistrict() {
      if (this.provinces.length > 0 && this.dataInfo.provinceId !== null) {
        const data = await this.fecthDistricts(this.dataInfo.provinceId)
        this.districts = data
        this.dataInfo.districtId = null
        this.choiceDistrict = true
      }
      if (this.dataInfo.provinceId === null) {
        this.dataInfo.districtId = null
        this.dataInfo.wardId = null
        this.choiceDistrict = false
        this.choiceWard = false
      }
    },

    async openSelectWard() {
      if (this.provinces.length > 0 && this.dataInfo.districtId !== null) {
        const data = await this.fecthWards(this.dataInfo.districtId)
        this.wards = data
        this.dataInfo.wardId = null
        this.choiceWard = true
      }
      if (this.dataInfo.districtId === null) {
        this.dataInfo.wardId = null
        this.choiceWard = false
      }
    },

    // Danh sách Tỉnh/ Thành phố
    async fetchProvinces() {
      const data = await this.fecthProvinces()
      this.provinces = data
    },

    uploadFile() {
      this.$refs['upload-file'].click()
    },
    importFileExcel(e) {
      this.dataInfo.fileName = this.$refs['upload-file'].files[0].name
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      this.dataInfo.modelFormData = {
        files: file,
        isSecure: false,
      }
    },
  },
}
</script>

<style lang="scss">
.checkbox-accident {
  .custom-checkbox {
    margin-top: 1rem;
  }
}

  .btn-button-filed {
    width: 35%;
    height: 38px;
    svg {
      margin-bottom: 4px;
    }
  }
    .input-file {
    display: none;
  }
</style>
