<template>
  <b-modal
    :id="id"
    :title="title"
    cancel-variant="outline-primary"
    size="xl"
    cancel-title="Quay lại"
    ok-title="Lưu"
    no-close-on-backdrop
    @ok="handlerAddWorker($event)"
    @hidden="resetData"
  >

    <validation-observer ref="rule">
      <general-information
        :dataGeneral="dataGeneral"
        :contractType="contractType"
        :arrPossition="arrPossition"
        @checkId="checkId"
      />
    </validation-observer>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import GeneralInformation from '@/views/management-workers/worker/pages/tag-list/GeneralInformation.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '@/views/management-workers/worker/constants/ConstantsApi'

export default {
  components: {
    BModal,
    GeneralInformation,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataGeneral: {
        avatar: null,
        gender: true,
        code: null,
        name: null,
        countryId: null,
        birthDayString: null,
        socialInsuranceCode: null,
        identityCardNumber: null,
        locationIssued: null,
        dateExpiredString: null,
        phoneNumber: null,
        address: null,
        email: null,
        academicLevel: 'University',
        typeOfProfessionId: null,
        workingFormId: null,
        provinceId: 0,
        districtId: 0,
        wardId: 0,
      },
      arrPossition: [],
      contractType: [],
    }
  },
  methods: {
    checkId(val) {
      if (val !== null) {
        this.userTypeId = val
        this.getAllComboBox(val)
        this.getComboBox(val)
      } else {
        this.userTypeId = 0
      }
    },

    async getAllComboBox(countryId) {
      const { data } = await axiosApiInstance.get('/Worker/get-combobox-position-worker', {
        params: { countryId },
      })
      this.arrPossition = data?.data
    },
    async getComboBox(countryId) {
      const { data } = await axiosApiInstance.get('/Worker/get-combobox-contract-type', {
        params: { countryId },
      })
      this.contractType = data?.data
    },
    resetData() {
      this.dataGeneral = {
        avatar: null,
        gender: true,
        code: null,
        name: null,
        countryId: null,
        birthDayString: null,
        socialInsuranceCode: null,
        identityCardNumber: null,
        locationIssued: null,
        dateExpiredString: null,
        phoneNumber: null,
        address: null,
        email: null,
        academicLevel: 'University',
        typeOfProfessionId: null,
        workingFormId: null,
        provinceId: 0,
        districtId: 0,
        wardId: 0,
      }
    },

    handlerAddWorker(e) {
      e.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          //
          axiosApiInstance.post(ConstantsApi.ADD, this.dataGeneral).then(res => {
            if (res.status === 200) {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast('Thêm thành công!', {
                title: 'Thông báo',
                variant: 'success',
                toaster: this.$toastPosition,
                solid: true,
              })
            }
            this.$emit('getListWorker', res?.data?.data.id)
          })
            // eslint-disable-next-line no-shadow
            .catch(e => {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast(e.response.data?.errors[0], {
                title: 'Thông báo',
                variant: 'danger',
                toaster: this.$toastPosition,
                solid: true,
              })
            })
        }
      })
    },
  },
}
</script>

<style>

</style>
